<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Solving One Equation with One Unknown - Part II</h2>
      <p class="mb-3">
        As noted in part I, if you have an equation that has a single unknown variable, you should
        be able to rearrange that equation in order to determine the value of said variable. The
        question is, how do you do these rearrangements. Let's take a look at the following
        equation.
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$\displaystyle{4 = \frac{24}{x}}$" />
      </p>

      <p class="mb-3">
        Remember that the goal is to isolate the unknown variable on one side of the equation. To
        move terms from one side of the equation to the other, you perform the OPPOSITE operation.
        For example, if a term is added on one side, you subtract it from both sides to move it to
        the opposite side of the equation. In Part I, we focused on isolating terms that are in the
        numerator (i.e. the top of a fraction). Here we will focus on the denominator (i.e. the
        bottom of the fraction). To determine the value of something, it cannot be in the
        denominator, so we need to move it to the other side of the equation. What is the opposite
        operation from division? Yes, multiplication. Applying this to our previous equation, if we
        multiply both sides by the denominator (i.e. the x), then we get:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\displaystyle{4x = \frac{24}{\cancel{x}}\cancel{x}} \hspace{0.5cm} \to \hspace{0.5cm}  \displaystyle{4x = 24}$"
        />
      </p>

      <p class="mb-3">
        Now we only have the 4 to deal with. Since it is multiplied by the x, we need to divide both
        sides by 4 to isolate the x.
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\displaystyle{\frac{\cancel{4}x}{\cancel{4}}}=\displaystyle{\frac{24}{4}} \hspace{0.5cm} \to \hspace{0.5cm} x = 6$"
        />
      </p>

      <p class="mb-3">
        Using this knowledge, try and determine the value of x for each of the following
        expressions.
      </p>

      <p class="mb-3">
        <stemble-latex content="$\text{a)}\hspace{0.2cm} \displaystyle{\frac{8}{x}} + 4 =~$" />
        <number-value :value="expr3val" />
      </p>

      <calculation-input
        v-model="inputs.x3"
        class="mb-5"
        prepend-text="$\text{x:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{b)}\hspace{0.2cm} \displaystyle{\frac{3}{4x}} - 1.5 =~$" />
        <number-value :value="expr4val" />
      </p>

      <calculation-input v-model="inputs.x4" prepend-text="$\text{x:}$" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question351b',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        x3: null,
        x4: null,
      },
    };
  },
  computed: {
    expr3val() {
      return this.taskState.getValueBySymbol('expr3val').content;
    },
    expr4val() {
      return this.taskState.getValueBySymbol('expr4val').content;
    },
  },
};
</script>
